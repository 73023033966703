import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { RecordData } from "@/types/types";
import { Record } from "@/components/simple/Record";
import { TextField } from "@mui/material";

interface SearchActivitiesProps {
  activities: RecordData[];
  onSelect: (activity: RecordData) => void;
  selectedActivities: RecordData[];
}

const ITEMS_PER_PAGE = 10;

const SearchActivities: React.FC<SearchActivitiesProps> = ({
  activities,
  selectedActivities,
  onSelect,
}) => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [displayedActivities, setDisplayedActivities] = useState<RecordData[]>(
    [],
  );
  const { ref, inView } = useInView({
    threshold: 0.1,
  });


  useEffect(() => {
    if (inView && page * ITEMS_PER_PAGE < activities.length) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, activities.length, page]);

  useEffect(() => {
    const startIndex = 0;
    const endIndex = page * ITEMS_PER_PAGE;
    setDisplayedActivities(activities.slice(startIndex, endIndex));
  }, [page, activities]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setPage(1);
  };

  const filteredActivities = activities.filter((activity) =>
    activity.name?.toLowerCase().includes(query.toLowerCase()),
  );

  return (
    <div className="mb-4">
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Søk etter "
        value={query}
        onChange={handleSearchChange}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-4">
        {filteredActivities
          .slice(0, displayedActivities.length)
          .map((activity) => (
            <div key={activity.id}>
              <Record
                id={activity.id}
                name={activity.name ?? ''}
                description={activity.description ?? ''}
                videoUrl={activity.videoUrl ?? ''}
                onClick={() => onSelect(activity)}
                selected={selectedActivities.some(a => a.id === activity.id)}
              />
            </div>
          ))}
        <div ref={ref}></div>
      </div>
    </div>
  );
};

export default SearchActivities;
