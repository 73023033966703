import React, { useEffect, useRef, useCallback, useState } from "react";
import { useSearchContext } from "@/contexts/SearchContext";
import { useRecords } from "@/contexts/RecordsContext";
import { useLanguage } from "@/contexts/LanguageContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Record from "@/components/simple/Record";
import { RecordData, SessionRecordData } from "@/types/types";

export const SessionRecord: React.FC = () => {
  const { searchData } = useSearchContext();
  const {
    setRecord,
    records,
    otherBodies,
    loading,
    total,
    otherTotal,
    clearRecords,
    fetchRecords,
  } = useRecords();
  const { currentLanguage } = useLanguage();
  const [skip, setSkip] = useState(0);
  const LIMIT = 50;

  const observerRef = useRef<IntersectionObserver | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const lastSessionRef = useCallback(
    (node: HTMLElement | null) => {
      if (loading || searchData.show_activities) return;

      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && records.length < total && otherBodies.length < otherTotal) {
          setSkip(prev => prev + LIMIT);
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [loading, records.length, total, otherBodies.length, otherTotal, searchData.show_activities],
  );

  useEffect(() => {
    setSkip(0);
    clearRecords();
  }, [searchData, clearRecords]);

  useEffect(() => {
    fetchRecords(skip, LIMIT, searchData, currentLanguage?.id || 2);
  }, [fetchRecords, currentLanguage, skip, LIMIT, searchData]);

  const handleSessionClick = (record: RecordData | SessionRecordData) => {
    setRecord(record);
    navigate(`/session/${record.id}/${record.original_language_id}`);
  };

  return (
    <>
      {records.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {records.map((record, index) => (
            <div
              key={record.id}
              ref={index === records.length - 1 ? lastSessionRef : null}
            >
              <Record
                id={record.id}
                name={record.title || ''}
                description={record.description || ''}
                videoUrl={''}
                onClick={() => handleSessionClick(record)}
              />
            </div>
          ))}
        </div>
      )}

      {otherBodies.length > 0 && (
        <div className="my-12">
          <div className="border-t border-gray-300">
            <p className="text-center text-gray-500 bg-white relative -top-3 inline-block left-1/2 transform -translate-x-1/2 px-4">
              {t('common.otherLanguages')}
            </p>
          </div>
        </div>
      )}

      {otherBodies.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {otherBodies.map((record) => (
            <div key={record.id}>
              <Record
                id={record.id}
                name={record.title || ''}
                description={record.description || ''}
                videoUrl={''}
                onClick={() => handleSessionClick(record)}
              />
            </div>
          ))}
        </div>
      )}

      {!loading && records.length === 0 && otherBodies.length === 0 && (
        <p className="text-xl text-center my-16">
          {t('common.noRecordsFound')}
        </p>
      )}

      {loading && (
        <div className="flex justify-center my-16">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      )}
    </>
  );
};