import { useState } from "react";
import { useTranslation } from "react-i18next";
import { YouTubeUrlModal } from "@/components/simple/YoutubeModal";
import { useCreateActivity } from "@/contexts/CreateActivityContext";

export const MediaBox = ({ initialUrl }: { initialUrl?: string }) => {
    const getYouTubeThumbnail = (url: string) => {
        const videoId = url.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=))([\w-]{11})/)?.[1];
        return videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : null;
    };

    const [showYouTubeModal, setShowYouTubeModal] = useState(false);
    const [videoThumbnail, setVideoThumbnail] = useState<string | null>(
        initialUrl ? getYouTubeThumbnail(initialUrl) : null
    );
    const { t } = useTranslation();
    const { handleVideoUpload } = useCreateActivity();


    const handleYouTubeSubmit = (url: string) => {
        handleVideoUpload(url);
        const thumbnail = getYouTubeThumbnail(url);
        setVideoThumbnail(thumbnail);
    };

    // const handleFileUpload = () => {
    //     const input = document.createElement('input');
    //     input.type = 'file';
    //     input.accept = 'image/*';
    //     input.multiple = true;
    //     input.onchange = (e: any) => {
    //         if (e.target.files) {
    //             handleImageUpload(e.target.files);
    //         }
    //     };
    //     input.click();
    // };

    return (
        <>
            <div className="w-full bg-white rounded-lg shadow-lg p-6 transition-all duration-300 ease-in-out">
                <div className="flex flex-col md:flex-row gap-4 w-full">
                    {/* Video Upload Option */}
                    <div
                        className="flex-1 flex flex-col items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:bg-blue-50 transition-all duration-200 cursor-pointer"
                        onClick={() => setShowYouTubeModal(true)}
                    >
                        {videoThumbnail ? (
                            <div className="relative w-full aspect-video">
                                <img
                                    src={videoThumbnail}
                                    alt="Video thumbnail"
                                    className="w-full h-full object-cover rounded"
                                />
                                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-200">
                                    <span className="text-white text-sm">{t('createActivity.changeVideo')}</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <svg className="w-10 h-10 text-gray-400 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                </svg>
                                <span className="text-sm font-medium text-gray-700">{t('createActivity.uploadVideo')}</span>
                            </>
                        )}
                    </div>
                    <div className="hidden md:block w-px bg-gray-200"></div>
                    {/* <div
                        className="flex-1 flex flex-col items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:bg-blue-50 transition-all duration-200 cursor-pointer"
                        onClick={handleFileUpload}
                    >
                        <svg className="w-10 h-10 text-gray-400 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <span className="text-sm font-medium text-gray-700">{t('createActivity.uploadImage')}</span>
                    </div> */}
                </div>
            </div>

            <YouTubeUrlModal
                isOpen={showYouTubeModal}
                onClose={() => setShowYouTubeModal(false)}
                onSubmit={handleYouTubeSubmit}
            />
        </>
    );
};