import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecords } from '@/contexts/RecordsContext';
import { RecordData } from '@/types/types';
import { ActivityViewer } from '@/components/composed/Activity/ActivityView';
import { useSearchContext } from '@/contexts/SearchContext';
import { useSessions } from '@/contexts/SessionContext';

const ActivityView: React.FC = () => {
    const { id, language_id } = useParams();
    const { record, loading, error, fetchRecord } = useRecords();
    const { searchData } = useSearchContext();
    const { fetchSession } = useSessions();

    useEffect(() => {
        if (!record && id && language_id) {
            if (searchData.show_activities) {
                fetchRecord(parseInt(id), "activities", parseInt(language_id));
            } else {
                fetchSession(parseInt(id), parseInt(language_id));
            }
        }
    }, [id, language_id, record, searchData.show_activities, fetchRecord, fetchSession]);

    if (loading) {
        return (
            <div className="flex justify-center my-4 container mx-auto">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="container mx-auto">
                <div className="text-xl text-red-600 text-center">
                    {error}
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto max-w-7xl">
            {record && (
                <ActivityViewer record={record as RecordData} />
            )}
        </div>
    );
};

export default ActivityView;
