import { useNavigate } from "react-router-dom";
import { useAuth } from '@/contexts/AuthContext';
import { useLanguage } from '@/contexts/LanguageContext';
import { KeyWord,  SessionRecordData } from '@/types/types.ts';
import { HeaderWithBack } from "@/components/composed/HeaderWithBack";
import clsx from 'clsx';

import { Field, Input, Label, Description, } from '@headlessui/react';
import { LanguageSelector } from "@/components/composed/LanguageSelector";
import { QuillEditor } from "@/components/simple/TextEditor";
import { useTranslation } from "react-i18next";
import { useCreateSession } from '@/contexts/CreateSessionContext';
import { SessionTagGrid } from "@/components/composed/Session/SessionTagGrid";
import { SessionSectionCreate } from "@/components/composed/Session/SessionSectionCreate";
import { useEffect } from "react";



export const SessionCreate: React.FC = () => {
    const { t } = useTranslation();
    const { session, setSession, handleInputChange, errors, handleLanguageChange } = useCreateSession();
    const { currentLanguage } = useLanguage();
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    const { sessionLanguage, setSessionLanguage } = useLanguage();

    const sessionLanguageId = sessionLanguage?.id ?? currentLanguage?.id ?? 1;

    const isChecked = (key: string, value: string) => {
        if (!session[key as keyof SessionRecordData]) {
            return false;
        }
        return (session[key as keyof SessionRecordData] as KeyWord[]).some(item => item.name === value);
    }

    useEffect(() => {
        handleLanguageChange(sessionLanguage);
    }, [sessionLanguage]);

    if (!isLoggedIn) {
        navigate('/signIn');
    }
    return (
        <div className="container mx-auto max-w-7xl">
            <HeaderWithBack>
                {/* TODO: Should this be its own component? */}
                <div className={`w-full max-w-md px-4 ${errors.name ? 'border border-red-500' : ''}`}>
                    <Field>
                        <Label className="text-sm/6 font-medium text-black">{t('createSession.name')}</Label>
                        <Description className="text-sm/6 text-black/50">{t('createSession.meaningful_name')}</Description>
                        <Input
                            className={clsx(
                                'mt-3 block w-full rounded-lg border-none bg-black/5 py-1.5 px-3 text-sm/6 text-black',
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',

                            )}
                            name="name"
                            value={session.name}
                            onChange={(e) => handleInputChange('name', e.target.value)}
                        />
                    </Field>
                </div>
                <LanguageSelector currentLanguage={sessionLanguage} setActivityLanguage={setSessionLanguage} hasError={!!errors.language_id}/>
            </HeaderWithBack>

            <div className="border-b-2 border-gray-200"></div>
            <QuillEditor
                value={session.description}
                hasError={!!errors.description}
                onChange={(value) => handleInputChange('description', value)}
            />
            <SessionTagGrid
                session={session}
                isChecked={isChecked}
                onChange={(section, value) => handleInputChange(section, value)}
                errors={errors}
            />

            <div className="py-4 border-b-2 border-gray-200 p-4"></div>
            <SessionSectionCreate
                session={session}
                language_id={sessionLanguageId}
                setSession={setSession}
            />
        </div>
    );
}