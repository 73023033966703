import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SessionViewer } from '@/components/composed/Session/SessionView';
import { useSessions } from '@/contexts/SessionContext';
const SessionView: React.FC = () => {
    const { id, language_id } = useParams();
    const { session, setSession, loading, error, fetchSession } = useSessions();

    useEffect(() => {
        if (id) {
            // Always fetch the session when id changes
            fetchSession(parseInt(id), parseInt(language_id!));
        }
        
        // Clear session when component unmounts or when id changes
        return () => {
            setSession(null);
        };
    }, [id, language_id, fetchSession, setSession]);

    if (loading) {
        return (
            <div className="flex justify-center my-4 container mx-auto">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="container mx-auto">
                <div className="text-xl text-red-600 text-center">
                    {error}
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto max-w-7xl relative">
            {session && <SessionViewer record={session} />}
        </div>
    );
};

export default SessionView;


