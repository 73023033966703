import React, { createContext, useContext, useState, ReactNode } from 'react';
import { RecordDataPost, ImageDataPost, RecordData, ActivityFieldsUpdate, BodyUpdate, Language } from '@/types/types';

import { BACKEND_REST_URL } from '@/main';

interface CreateActivityContextType {
    activityPost: RecordDataPost;
    setActivityPost: React.Dispatch<React.SetStateAction<RecordDataPost>>;
    images: ImageDataPost[];
    setImages: React.Dispatch<React.SetStateAction<ImageDataPost[]>>;
    errors: { [key: string]: string };
    setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    handleInputChange: (name: string, value: string) => void;
    handlePost: (activity: RecordDataPost, images: ImageDataPost[], language_id: number) => Promise<void>;
    handleUpdate: (record: RecordData) => Promise<RecordData>;
    handleImageUpload: (newFiles: FileList) => void;
    handleVideoUpload: (string: string) => void;
    deleteImageAtIndex: (indexToDelete: number) => void;
    handleThumbnailChange: (index: number) => void;
    isChecked: (section: string, value: string) => boolean;
    validateActivity: () => boolean;
    handlePostBody: (body: BodyUpdate, activityId: number) => Promise<RecordData>;
    handleLanguageChange: (language: Language | null) => void;
}

const CreateActivityContext = createContext<CreateActivityContextType | undefined>(undefined);

interface CreateActivityProviderProps {
    children: ReactNode;
}

            const initialActivity: RecordDataPost = {
                name: '',
                description: '',
                language_id: 0,
                branches: [],
                environments: [],
                skill_levels: [],
                categories: [],
                focus_groups: [],
                disabilities: [],
                equipment: [],
            };

export const CreateActivityProvider: React.FC<CreateActivityProviderProps> = ({ children }) => {


    const [activityPost, setActivityPost] = useState<RecordDataPost>(initialActivity);
    const [images, setImages] = useState<ImageDataPost[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});



    const handleUpdate = async (record: RecordData) => {
        const recordFields: ActivityFieldsUpdate = {
            videoUrl: record.videoUrl || '',
            branches: record.branches.map(branch => branch.name) || [],
            environments: record.environments.map(environment => environment.name) || [],
            skill_levels: record.skill_levels.map(skill_level => skill_level.name) || [],
            categories: record.categories.map(category => category.name) || [],
            focus_groups: record.focus_groups.map(focus_group => focus_group.name) || [],
            disabilities: record.disabilities.map(disability => disability.name) || [],
            equipment: record.equipment.map(equipment => equipment.name) || [],
        }
        const fields: ActivityFieldsUpdate = {
            branches: activityPost.branches || [],
            environments: activityPost.environments || [],
            skill_levels: activityPost.skill_levels || [],
            categories: activityPost.categories || [],
            focus_groups: activityPost.focus_groups || [],
            disabilities: activityPost.disabilities || [],
            equipment: activityPost.equipment || ['Ingen'],
        }
        const fieldsToUpdate = Object.keys(fields).filter(key => fields[key as keyof ActivityFieldsUpdate] !== recordFields[key as keyof ActivityFieldsUpdate]);
        const body: BodyUpdate = {
            name: activityPost.name,
            description: activityPost.description,
            adaptations: activityPost.adaptations || '',
            tips: activityPost.tips || '',
            videoUrl: activityPost.videoUrl || '',
            language_id: activityPost.language_id,
        }


        if (fieldsToUpdate.length > 0) {
            fields.videoUrl = activityPost.videoUrl || '';
            fields.name = activityPost.name;
            fields.description = activityPost.description;
            fields.language_id = activityPost.language_id;
            fields.disabilities = activityPost.disabilities || [];
            fields.equipment = activityPost.equipment || ['Ingen']; // TODO: Why is this hardcoded?
            await handleUpdateFields(fields, record.id);
        }
        const bodyToUpdate = Object.keys(body).filter(key => body[key as keyof BodyUpdate] !== record[key as keyof RecordData]);
        if (bodyToUpdate.length > 0) {
            setActivityPost({ ...activityPost, ...fields, ...body });
            return await handleUpdateBody(body, record.id);
        }
    }
    const handleUpdateBody = async (body: BodyUpdate, activityId: number,) => {
        try {
            const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
            if (!token) {
                throw new Error('No authentication token found');
            }
            const response = await fetch(`${BACKEND_REST_URL}/api/activities/${activityId}/body`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText} response: ${response}`);
            }
            const activityData = await response.json();
            return activityData;
        } catch (error) {
            console.error('Error updating activity body:', error);
            throw error;
        }
    }
    const handlePostBody = async (body: BodyUpdate, activityId: number) => {
        try {
            const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
            if (!token) {
                throw new Error('No authentication token found');
            }
            const response = await fetch(`${BACKEND_REST_URL}/api/activities/${activityId}/body`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            });
            if (response.status === 409) {
                throw new Error('Activity already exists in this language');
            }
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText} response: ${response}`);
            }
            const activityData = await response.json();
            setActivityPost({} as RecordDataPost);
            return activityData;
        } catch (error) {
            console.error('Error posting activity body:', error);
            throw error;
        }
    }
    const handleUpdateFields = async (fields: ActivityFieldsUpdate, activityId: number) => {
        try {
            const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
            if (!token) {
                throw new Error('No authentication token found');
            }
            const response = await fetch(`${BACKEND_REST_URL}/api/activities/${activityId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(fields),
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText} response: ${response}`);
            }
        } catch (error) {
            console.error('Error updating activity fields:', error);
            throw error;
        }

    }

    const handleLanguageChange = (language: Language | null) => {
        if (language) {
            setActivityPost(prev => ({
                ...prev,
                language_id: language.id,
            }));
        }
    }

    const handleInputChange = (name: string, value: string) => {
        if (Array.isArray(activityPost[name as keyof RecordDataPost])) {
            if ((activityPost[name as keyof RecordDataPost] as string[]).includes(value)) {
                setActivityPost(prev => ({
                    ...prev,
                    [name]: (prev[name as keyof RecordDataPost] as string[]).filter((item: string) => item !== value),
                }));
            } else {
                setActivityPost(prev => ({
                    ...prev,
                    [name]: [...(prev[name as keyof RecordDataPost] as string[]), value],
                }));
            }
        } else {
            setActivityPost(prev => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const handleVideoUpload = (string: string) => {
        setActivityPost(prev => ({
            ...prev,
            videoUrl: string,
        }));
    };
    const isChecked = (name: string, value: string) => {
        if (!activityPost[name as keyof RecordDataPost]) {
            return false;
        }

        return (activityPost[name as keyof RecordDataPost] as string[]).includes(value);
    };

    const validateActivity = (): boolean => {
        const newErrors: { [key: string]: string } = {};
        const requiredFields = ['name', 'description', 'branches', 'environments', 'skill_levels', 'categories', 'focus_groups', 'language_id'];

        requiredFields.forEach(field => {
            const value = activityPost[field as keyof RecordDataPost];
            if (!value || (Array.isArray(value) && value.length === 0)) {
                newErrors[field] = `${field} is required`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePost = async (activity: RecordDataPost, images: ImageDataPost[], language_id: number) => {
        activity.language_id = language_id;
        try {
            const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${BACKEND_REST_URL}/api/activities/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(activity),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText} response: ${response}`);
            }

            const activityData = await response.json();

            if (images.length > 0) {
                await Promise.all(images.map(async ({ file, isThumbnail }) => {
                    const formData = new FormData();
                    formData.append('file', file);
                    await fetch(`${BACKEND_REST_URL}/api/activity/${activityData.id}/images?is_thumbnail=${isThumbnail}`, {
                        method: 'POST',
                        body: formData,
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                }));
            }
        } catch (error) {
            console.error('Error posting activity:', error);
            throw error;
        }
    };


    const handleImageUpload = (newFiles: FileList) => {
        const fileArray = Array.from(newFiles);
        const newImagePosts: ImageDataPost[] = fileArray.map((file, index) => ({
            file,
            isThumbnail: images.length === 0 && index === 0,
        }));
        setImages(prev => [...prev, ...newImagePosts]);
    };

    const deleteImageAtIndex = (indexToDelete: number) => {
        setImages(prev => prev.filter((_, index) => index !== indexToDelete));
    };

    const handleThumbnailChange = (index: number) => {
        setImages(prev => prev.map((image, idx) => ({
            ...image,
            isThumbnail: idx === index,
        })));
    };




    return (
        <CreateActivityContext.Provider
            value={{
                activityPost,
                setActivityPost,
                images,
                setImages,
                errors,
                setErrors,
                handleInputChange,
                handleUpdate,
                isChecked,
                handlePost,
                handleImageUpload,
                handlePostBody,
                handleVideoUpload,
                deleteImageAtIndex,
                handleThumbnailChange,
                validateActivity,
                handleLanguageChange,
            }}
        >
            {children}
        </CreateActivityContext.Provider>
    );
};

export const useCreateActivity = (): CreateActivityContextType => {
    const context = useContext(CreateActivityContext);
    if (!context) {
        throw new Error('useCreateActivity must be used within a CreateActivityProvider');
    }
    return context;
};
